export const saveUserToLocalStorage = (userData) => {
  localStorage.setItem('user', JSON.stringify(userData));
};

export const getUserFromLocalStorage = () => {
  const userJSON = localStorage.getItem('user');
  return userJSON ? JSON.parse(userJSON) : null;
};

export const clearUserFromLocalStorage = () => {
  localStorage.removeItem('user');
};