import React from 'react'

const TransactionItemList = ({ data, payload, setPayload }) => {
  const handleChangeItem = (id, desc) => {
    let tempData = { ...payload }
    tempData.item_id = id
    tempData.description = desc
    setPayload(tempData)
  }
  return (
    <>
      {data?.length > 0 ?
        <div className='py-4 px-5 border-2 border-gray-300 my-3 rounded-md'>
          <div className='overflow-y-auto min-h-[10vh] max-h-[50vh]'>
            <div className='grid grid-cols-2 gap-2'>
              {data?.map((val, i) =>
                <label htmlFor={val.id + 1} key={val?.name + 1} className={`py-2 px-3 rounded-md ${val.status !== 'available' || val.available !== 'available' ? 'bg-red-400' : val?.id === payload?.item_id ? 'bg-gray-400/35 hover:cursor-pointer' : 'bg-gray-200/35 hover:cursor-pointer'}`}>
                  <span className='block'>{val?.code}</span>
                  <span className='block'>{val?.name}</span>
                  <GetPrices prices={val?.prices} />
                  <input
                    id={val.id + 1}
                    type={'radio'}
                    name={'item_id'}
                    className='hidden'
                    disabled={val.status !== 'available' || val.available !== 'available'}
                    onChange={(e) => handleChangeItem(val?.id, val?.name)} />
                </label>
              )}
            </div>
          </div>
        </div>
        :
        <div>
          Select game first
        </div>
      }
    </>
  )
}

const GetPrices = ({ prices }) => {
  let price = 0
  prices?.map((val) => {
    if (val?.type === 'special') {
      price = val?.price + val?.margin
    }
  })

  return (
    <>
      <span className='block text-[14px]'>
        {new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(price)}
      </span>
    </>
  )
}

export default TransactionItemList