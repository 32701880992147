import HeaderPage from '../components/headers/HeaderPage'
import TransactionList from '../components/transactions/TransactionList'

export default function Transaction() {
  return (
    <>
      <HeaderPage page={"Transactions"} />
      <div className='py-10 max-w-full overflow-auto'>
        <TransactionList />
      </div>
    </>
  )
}
