import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const RecursiveLink = (data, location, handleChangeLocation) => {
  return data.map((val, i) => {
    return (
      <React.Fragment key={val.parent ? "parent-" + val.text + i : "child-" + val.text + i}>
        {val.parent ? (
          <div className='pt-10'>
            <li className='text-[#d6d6d6] text-[12px] md:text-[16px]'>
              {val.text}
            </li>
            <ul className={`list-none text-white text-[16px] md:text-[20px]`}>
              {RecursiveLink(val.childs, location, handleChangeLocation)}
            </ul>
          </div>
        ) : (
          <Link to={val.href}>
            <li
              key={"child-" + val.text + i}
              onClick={() => handleChangeLocation(val.href)}
              className={`pl-10 flex items-center py-3 my-1 hover:bg-slate-700 hover:rounded-2xl hover:cursor-pointer ${(location === '/' && val?.href === location) || (location !== '/' && val?.href.includes(location)) ? 'bg-slate-700 rounded-2xl' : ''}`}>
              <FontAwesomeIcon icon={val.icon} className='mr-3' />
              {val.text}
            </li>
          </Link>
        )}
      </React.Fragment>
    );
  });
};

export default RecursiveLink