import { createSlice } from '@reduxjs/toolkit'

const initialStateAuth = {
  logged: false,
  user: {}
}

const initialState = {
  auth: initialStateAuth,
  loading: false,
  error: null
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginStart(state) {
      state.loading = true
      state.error = null
    },
    loginSuccess(state, action) {
      state.loading = false
      state.auth.user = action.payload?.data
      state.auth.logged = true
      state.error = null
    },
    loginFailure(state, action) {
      state.loading = false
      state.error = action.payload
    },
    logout(state) {
      state.auth = initialStateAuth
      state.loading = false
      state.error = null
    },
    loginSetFromStorage(state, action) {
      state.auth = action.payload
      state.auth.logged = true
    },
  }
})

export const { loginStart, loginSuccess, loginFailure, logout } = authSlice.actions
export default authSlice.reducer