import React, { useEffect, useState } from 'react'
import HeaderPage from '../components/headers/HeaderPage'
import axios from '../api/axios'
import { useParams } from 'react-router-dom'
import LayoutLoad from '../layouts/LayoutLoad'

const PaymentDetail = () => {
  const { paymentID } = useParams()
  const [err, setErr] = useState('')
  const [loading, setLoading] = useState(false)
  const [payment, setPayment] = useState([])
  const [editMode, setEditMode] = useState(false)

  useEffect(() => {
    loadDetailPayment(paymentID, setPayment, setLoading, setErr)
  }, [])

  return (
    <>
      <LayoutLoad err={err} loading={loading} setErr={(data) => setErr(data)}>
        <HeaderPage page={`Payment ${payment?.name}`} />
        <div className='py-10'>
          <div className='grid grid-cols-6 gap-4'>
            
          </div>
        </div>
      </LayoutLoad>
    </>
  )
}

const loadDetailPayment = async (paymentID, setPayment, setLoading, setErr) => {
  setLoading(true)
  await axios.get(`/payment/${paymentID}`)
    .then((response) => {
      setLoading(false)
      setPayment(response.data.data)
    })
    .catch((e) => {
      setLoading(false)
      if (e?.response?.data?.message) {
        setErr(e.response.data.message)
      } else {
        setErr(e.message)
      }
    })
}

export default PaymentDetail