import React, { useEffect, useState } from 'react'
import HeaderSection from '../headers/HeaderSection'
import FormSelect from '../forms/FormSelect'
import { InputWithLabel } from '../forms/FormInputs'
import { ButtonBlue } from '../buttons/Buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import { fieldOptions } from '../../constants/fieldOptions'

const GameSectionFIeld = ({ payload, handleChangePayload, editMode }) => {
  // const [options, setOptions] = useState('')

  const handleAddField = () => {
    var fields = payload?.get('fields')
    console.log("INI LOG ", fields);
    fields.push(new Map())
    handleChangePayload('fields', fields)
  }

  const handleChangeField = (key, value, index) => {
    var fields = payload?.get('fields')
    fields[index].set(key, value)

    handleChangePayload('fields', fields)
  }

  const handleRemoveField = (index) => {
    var fields = payload.get('fields')
    fields.splice(index, 1)

    handleChangePayload('fields', fields)
  }

  return (
    <>
      <div className='py-10'>
        <div className='flex'>
          <HeaderSection section={'Game Fields'} />
          {editMode &&
            <div className='ml-auto pr-6'>
              <ButtonBlue text={'Add Field'} icon={<FontAwesomeIcon icon={faPlus} />} onClick={handleAddField} />
            </div>
          }
        </div>
        <div className='bg-white px-5 py-8 shadow-xl rounded-xl'>
          {payload?.get('fields') ? (
            payload.get('fields').map((val, i) => (
              <div className='flex' key={i}>
                <span className='border-stone-700 border-l-2 text-[28px] flex items-end px-2 pr-4'>{i + 1}.</span>
                <div className='grid grid-cols-5 gap-5 py-2'>
                  <InputWithLabel
                    value={val?.get('field')}
                    type={'text'}
                    name={'field'}
                    id={'field'}
                    label={"Field"}
                    readonly={!editMode}
                    onchange={(key, value) => handleChangeField(key, value, i)} />

                  <InputWithLabel
                    value={val?.get('placeholder')}
                    type={'text'}
                    name={'placeholder'}
                    id={'placeholder'}
                    label={"Placeholder"}
                    readonly={!editMode}
                    onchange={(key, value) => handleChangeField(key, value, i)} />

                  <InputWithLabel
                    value={val?.get('description')}
                    type={'text'}
                    name={'description'}
                    id={'description'}
                    label={"Description"}
                    readonly={!editMode}
                    onchange={(key, value) => handleChangeField(key, value, i)} />

                  <FormSelect
                    id={'type-options'}
                    name={'type'}
                    label={'Select type'}
                    disabled={!editMode}
                    selected={val?.get('type')}
                    options={fieldOptions}
                    defaultData={'Choose type'}
                    onchange={(key, value) => handleChangeField(key, value, i)} />

                  <InputOptions field={val} index={i} editmode={editMode} handleChangeField={handleChangeField} />
                </div>
                <span className='text-[18px] flex items-end px-2 top-0 h-full mt-8 cursor-pointer' onClick={() => editMode ? handleRemoveField(i) : ''}><FontAwesomeIcon icon={faTimes} /></span>
              </div>
            ))
          ) : (
            <div className='flex'>Not field found in this game...</div>
          )}
        </div>
      </div>
    </>
  )
}

const InputOptions = ({ field, index, editmode, handleChangeField }) => {
  const [options, setOptions] = useState([])

  useEffect(() => {
    let option = []
    field?.get('options')?.map((val, i) => {
      option.push(`${val?.get('label').trim()} : ${val?.get('value').trim()}`)
      setOptions(option)
    })
  }, [field])

  const handleChange = (key, value) => {
    let option = value.split(',')
    let trimOption = []
    option.forEach((val, i) => {
      val.trim().length > 0 && trimOption.push(val.trim())
    })
    setOptions(trimOption)
  }

  const keyDown = (key, value) => {
    value = value + ', key : value'
    handleChange(key, value)
  }

  useEffect(() => {
    let option = []
    options.map((value, i) => {
      let values = value.split(':')
      console.log(option);
      console.log(i);
      if (option && option[i]) {
        option[i].set('label', values[0]?.trim() ?? '')
        option[i].set('value', values[1]?.trim() ?? '')
      } else {
        option.push(new Map())
        option[i].set('label', values[0]?.trim() ?? '')
        option[i].set('value', values[1]?.trim() ?? '')
      }
    })

    handleChangeField('options', option, index)
  }, [options])

  return (
    <InputWithLabel value={options.join(', ')} type={'text'} name={'options'} id={'options'} label={"Options"} readonly={!editmode || field?.get('type') !== 'select'} onchange={handleChange} onkeydown={keyDown} />
  )
}

export default GameSectionFIeld