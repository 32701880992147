import React, { useState } from 'react'
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from '../components/sidebar/Sidebar';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearUserFromLocalStorage, getUserFromLocalStorage } from '../hooks/auth/util/authUtil';
import { loginUser } from '../hooks/auth/thunk/loginThunk';
import { refreshTokenThunk } from '../hooks/auth/thunk/refreshTokenThunk';
import { ButtonBlue } from '../components/buttons/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignLeft, faAlignRight, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { logout } from '../hooks/auth/authSlice';

const Layout = () => {
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  const navigate = useNavigate(); 

  const [navOpen, setNavOpen] = useState(false)
  

  useEffect(() => {
    const userDataRefresh = getUserFromLocalStorage()
    userDataRefresh ? dispatch(refreshTokenThunk(userDataRefresh)) : logOut()
  }, [auth?.auth?.logged])

  const logOut = () => {
    clearUserFromLocalStorage()
    dispatch(logout())
    // window.location.href = '/login'
    navigate('/login')
  }

  return (
    <>
      <Sidebar navOpen={navOpen} setNavOpen={setNavOpen} />
      <div className='w-full m-0 p-0'>
        <div className='grid md:grid-cols-12 gap-10'>
          <div className='md:col-start-3 md:col-end-13 py-5 relative'>
            <div className='sticky top-0 z-20'>
              <div className='bg-blue-800/20 my-5 py-5 px-10 flex items-center gap-6 justify-between'>
                <div className='md:hidden text-2xl'>
                  <FontAwesomeIcon icon={faAlignLeft} onClick={() => setNavOpen(!navOpen)} />
                </div>
                <div>
                  <div className='inline-block'>
                    <span className='block text-[14px] md:text-[16px] font-semibold'>{getUserFromLocalStorage()?.fullname}</span>
                    <span className='block text-[10px] md:text-[12px]'>{getUserFromLocalStorage()?.roles?.name}</span>
                  </div>
                  <ButtonBlue text={"Logout"} icon={<FontAwesomeIcon icon={faSignOut} />} className={'bg-red-800'} onClick={logOut} />
                </div>
              </div>
            </div>
            <div className='h-fit overflow-y-auto pl-5 pr-10'>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Layout