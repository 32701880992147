import React, { useState, useEffect } from 'react'
import { ButtonBlue, ButtonTransaparent } from '../buttons/Buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckDouble, faSpinner } from '@fortawesome/free-solid-svg-icons'
import HeaderSection from '../headers/HeaderSection'
import FormSelect from '../forms/FormSelect'
import axios from '../../api/axios'
import { InputWithLabel } from '../forms/FormInputs'
import TransactionItemList from './TransactionItemList'
import TransactionModal from './TransactionModal'
import ErrorPage from '../../page/ErrorPage'

const CreateTransaction = ({ cancel }) => {
  const [payload, setPayload] = useState({})
  const [fieldFilled, setFieldFilled] = useState(new Map())
  const [id, setId] = useState('')
  const [game, setGame] = useState([])
  const [intItems, setIntItems] = useState([])
  const [createResponse, setCreateResponse] = useState([])
  const [gameSelected, setGameSelected] = useState({})
  const [err, setErr] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingItemInt, setLoadingItemInt] = useState(false)
  const [loadingGameSelected, setLoadingGameSelected] = useState(false)
  const [loadingCreateTransaction, setLoadingCreateTransaction] = useState(false)

  const [openDetail, setOpenDetail] = useState(false)

  const handleOnChange = (key, value) => {
    setId(value)
    execGetIntItems(setIntItems, setLoadingItemInt, setErr, value)
    execGetGameSelected(setGameSelected, setLoadingGameSelected, setErr, value)
    setFieldFilled(new Map())
  }

  useEffect(() => {
    setLoading(true)
    const execGetGame = async () => {
      try {
        const response = await reqGameList()
        setGame([
          ...response.map((val) => ({ value: val.id, text: val.name }))
        ])
      } catch (e) {
        setLoading(false)
        setErr(e)
      }
    }

    execGetGame()
    setLoading(false)
  }, [])

  // Create Transaction
  const handleFieldChange = (key, value) => {
    let tempField = new Map(fieldFilled)
    tempField.set(key, value)
    setFieldFilled(tempField)
  }

  const handleSubmitTransaction = () => {
    let tempOption = []
    fieldFilled?.forEach((value, key) => {
      tempOption.push({ name: key, value: value })
    })
    let data = { ...payload }
    data.options = tempOption
    data.info = 'tabsgameofficial@gmail.com'

    execCreateTransaction(setCreateResponse, setLoadingCreateTransaction, setOpenDetail, setErr, data)
  }

  return (
    <>
      {err && 
        <ErrorPage err={err} setErr={setErr} />
      }
      {openDetail &&
        <TransactionModal close={() => {
          setOpenDetail(!openDetail)
          cancel()
        }} data={createResponse} />
      }
      <div className=' fixed top-0 right-0 left-0 z-50 w-full h-full bg-black/40'>
        <div className='flex justify-center items-center w-full h-full'>
          <div className='relative p-4 w-full max-w-2xl max-h-full'>
            <div className='relative bg-white rounded-lg shadow px-7 py-5'>
              <div className='py-3'>
                <div className='flex'>
                  <HeaderSection section={'Create Transaction'} />
                  <div className='ml-auto'>
                    <FormSelect
                      id={'game_select'}
                      name={'game_select'}
                      defaultData={'Choose Game'}
                      options={game}
                      disabled={false}
                      selected={id}
                      onchange={handleOnChange} />
                  </div>
                </div>
              </div>
              <div>
                <div className={`grid gap-4 grid-cols-${gameSelected?.fields?.length ?? 1}`}>
                  {gameSelected?.fields?.map((val, i) =>
                    val?.type === 'select' ?
                      <FormSelectStrict value={val} index={i} key={val.field} onchange={handleFieldChange} selected={fieldFilled.get(val.field)} />
                      :
                      <InputWithLabel
                        key={val.field}
                        id={val.field}
                        name={val.field}
                        label={val.placeholder}
                        type={val.type}
                        placeholder={val.placeholder}
                        value={fieldFilled.get(val.field) ?? ''}
                        onchange={handleFieldChange} />
                  )}
                </div>
                <div>
                  <TransactionItemList data={intItems} payload={payload} setPayload={setPayload} />
                </div>
              </div>
              <div className='flex py-3'>
                <div className='ml-auto'>
                  <ButtonTransaparent disabled={loadingCreateTransaction} text={'Cancel'} icon={loadingCreateTransaction ? <FontAwesomeIcon icon={faSpinner} spin /> : ''} onClick={() => cancel()} />
                  <ButtonBlue disabled={loadingCreateTransaction} text={'Submit'} icon={loadingCreateTransaction ? <FontAwesomeIcon icon={faSpinner} spin /> : <FontAwesomeIcon icon={faCheckDouble} />} onClick={handleSubmitTransaction} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const reqGameList = async () => {
  return await axios.get('/game')
    .then((response) => {
      return response.data.data
    })
    .catch((e) => {
      if (e?.response?.data?.message) {
        throw e.response.data.message + ' cause ' + e.response.data.errors
      } else {
        throw e.message;
      }
    });
}

const reqIntItems = async (id) => {
  return await axios.get(`/item/${id}`)
    .then((response) => {
      return response.data.data
    })
    .catch((e) => {
      if (e?.response?.data?.message) {
        throw e.response.data.message + ' cause ' + e.response.data.errors
      } else {
        throw e.message;
      }
    });
}

const execGetIntItems = async (setIntItems, setLoadingItemInt, setErr, id) => {
  if (id !== 'Choose Game') {
    setIntItems([])
    setLoadingItemInt(true)
    try {
      const response = await reqIntItems(id)
      console.log(response);
      setIntItems([
        ...response
      ])
      setLoadingItemInt(false)
    } catch (e) {
      setLoadingItemInt(false)
      setErr(e)
    }
  }
}

const reqGameSelected = async (id) => {
  return await axios.get(`/game/${id}`)
    .then((response) => {
      return response.data.data
    })
    .catch((e) => {
      if (e?.response?.data?.message) {
        throw e.response.data.message + ' cause ' + e.response.data.errors
      } else {
        throw e.message;
      }
    });
}

const execGetGameSelected = async (setGameSelected, setLoadingGameSelected, setErr, id) => {
  if (id !== 'Choose Game') {
    setGameSelected({})
    setLoadingGameSelected(true)
    try {
      const response = await reqGameSelected(id)
      console.log(response);
      setGameSelected({ ...response })
      setLoadingGameSelected(false)
    } catch (e) {
      setGameSelected(false)
      setErr(e)
    }
  }
}

const FormSelectStrict = ({ value, index, onchange, selected }) => {
  let tempData = []
  value?.options?.map((op) => {
    tempData.push({ value: op.value, text: op.label })
  })

  return (
    <FormSelect
      id={value.field}
      label={value.placeholder}
      name={value.field}
      options={tempData}
      onchange={onchange}
      selected={selected} />
  )
}

const reqCreateTransaction = async (payload) => {
  return await axios.post(`/transaction/create`, payload, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((response) => {
      return response.data.data
    })
    .catch((e) => {
      if (e?.response?.data?.message) {
        throw e.response.data.message + ' cause ' + e.response.data.errors
      } else {
        throw e.message;
      }
    });
}

const execCreateTransaction = async (setResponseCreate, setLoadingCreate, setOpenDetail, setErr, payload) => {
  setResponseCreate({})
  setLoadingCreate(true)
  try {
    const response = await reqCreateTransaction(payload)
    console.log(response);
    setResponseCreate({ ...response })
    setLoadingCreate(false)
    setOpenDetail(true)
  } catch (e) {
    setLoadingCreate(false)
    setErr(e)
  }
}

export default CreateTransaction