import React from 'react'

const FormFileImage = ({ setImage, image, setFile, id, name, readonly }) => {
  const handleChangeImage = (e) => {
    const selectedImage = e.target.files[0];
    console.log(e.target.files);
    setFile(e.target.files)
    if (selectedImage) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setImage(event.target.result);
      };
      reader.readAsDataURL(selectedImage);
    }
  }

  return (
    <>
      <label className='px-2 text-[14px]' htmlFor={id}>Image</label>
      <div className="flex items-center justify-center w-full">
        <label htmlFor={id} className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800">
          <div className="flex flex-col items-center justify-center relative h-full w-full">
            {image ?
              <img src={image} alt="" className='h-full float-left' /> :
              <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
              </svg>
            }
            {
              !readonly &&
              <div className={`absolute flex h-full w-full items-end justify-center z-10 pb-7 ${image ? 'from-[#0000005d] to-transparent bg-gradient-to-t' : ''}`}>
                <div>
                  <p className={`mb-2 text-sm ${image ? 'text-white' : 'text-gray-500'}`}><span className="font-semibold">Click to upload</span> or drag and drop</p>
                  <p className={`text-xs ${image ? 'text-white' : 'text-gray-500'}`}>SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                </div>
              </div>
            }
          </div>
          <input id={id} type="file" name={name} className="hidden" onChange={(e) => handleChangeImage(e)} disabled={readonly}/>
        </label>
      </div>
    </>
  )
}

export default FormFileImage