import React, { useEffect, useState } from 'react'
import LayoutLoad from '../layouts/LayoutLoad'
import { DEFAULT_PNG } from '../assets/assets'
import HeaderPage from '../components/headers/HeaderPage'
import PaymentCard from '../components/payment/PaymentCard'
import { useNavigate } from 'react-router-dom'
import axios from '../api/axios'

const Payment = () => {
  const [err, setErr] = useState('')
  const [loading, setLoading] = useState(false)
  const [payment, setPayment] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    loadPaymentList(setPayment, setLoading, setErr)
  }, [])

  return (
    <>
      <LayoutLoad err={err} loading={loading} setErr={(data) => setErr(data)}>
        <HeaderPage page={'Payment'} />
        <div className='py-10'>
          <div className='grid grid-cols-6 gap-4'>
            {payment.map((val, i) =>
              <PaymentCard
                image={val?.image ? val.image : DEFAULT_PNG}
                name={val?.name} onClick={() => navigate('/payment/' + val?.id)}
                active={val?.status === 'available'} />
            )}
          </div>
        </div>
      </LayoutLoad>
    </>
  )
}

const loadPaymentList = async (setPayment, setLoading, setErr) => {
  setLoading(true)
  await axios.get('/payment')
    .then((response) => {
      setLoading(false)
      setPayment(response?.data?.data)
    })
    .catch((e) => {
      setLoading(false)
      if (e?.response?.data?.message) {
        setErr(e.response.data.message)
      } else {
        setErr(e.message)
      }
    })
}

export default Payment