import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { ButtonBlue, ButtonTransaparent } from '../components/buttons/Buttons'
import { setToInitial } from '../hooks/game/gameSlice'

import HeaderPage from '../components/headers/HeaderPage'
import axios from '../api/axios'
import LayoutLoad from '../layouts/LayoutLoad'
import GameSectionDetail from '../components/games/GameSectionDetail'
import GameSectionFIeld from '../components/games/GameSectionFIeld'

const GameDetail = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { slug } = useParams()
  const [err, setErr] = useState('')
  const [loading, setLoading] = useState(false)
  const [payload, setPayload] = useState(new Map())
  const [editMode, setEditMode] = useState(false)

  const handleChangePayload = (key, value) => setPayload(prevPayload => new Map(prevPayload).set(key, value));
  useEffect(() => {
    const loadDetail = async () => {
      setLoading(true)
      await axios.get(`/game/s/${slug}`)
        .then((response) => {
          setLoading(false)
          const data = response?.data?.data;
          const fieldsMap = []
          if (data.fields && Array.isArray(data.fields)) {
            data.fields.forEach(field => {
              let tempOptions = []
              field?.options.forEach(option => {
                tempOptions.push(new Map(Object.entries(option)))
              })

              field.options = tempOptions
              fieldsMap.push(new Map(Object.entries(field)));
            });
          }
          const mergedPayload = {
            ...data,
            fields: fieldsMap,
            image_upload: data.image,
          };
          setPayload(new Map(Object.entries(mergedPayload)));
        })
        .catch((e) => {
          setLoading(false)
          if (e?.response?.data?.message) {
            setErr(e.response.data.message)
          } else {
            setErr(e.message)
          }
        })
    }

    loadDetail()
  }, [slug])

  const handleUpdate = () => {
    const fields = []
    const file = payload.get('file')
    payload.get('fields').forEach((field, i) => {
      let tempField = {}
      let tempOptions = []
      field?.get('options').forEach(option => {
        tempOptions.push(JSON.parse(JSON.stringify(Object.fromEntries(option))))
      })

      tempField = JSON.parse(JSON.stringify(Object.fromEntries(field)))
      tempField.options = tempOptions
      fields.push(tempField)
    })
    let request = JSON.parse(JSON.stringify(Object.fromEntries(payload)))
    request.fields = fields
    request.file = file

    reqUpdate(request, (e) => setErr(e), (e) => setLoading(e), navigate, dispatch)
  }

  return (
    <>
      <LayoutLoad err={err} setErr={(data) => setErr(data)} loading={loading}>
        <HeaderPage page={`Detail ${payload.get('name')}`} />
        <GameSectionDetail payload={payload} handleChangePayload={(key, value) => handleChangePayload(key, value)} editMode={editMode} />
        <GameSectionFIeld payload={payload} handleChangePayload={(key, value) => handleChangePayload(key, value)} editMode={editMode} />
        <div className='flex float-end'>
          {editMode ?
            <>
              <ButtonTransaparent text={'Cancel'} onClick={() => setEditMode(false)} />
              <ButtonBlue text={'Save Changes'} onClick={handleUpdate} />
            </> :
            <>
              <ButtonTransaparent text={'Back'} onClick={() => navigate(-1)} />
              <ButtonBlue text={'Edit'} onClick={() => setEditMode(true)} />
            </>
          }
        </div>
      </LayoutLoad>
    </>
  )
}

const reqUpdate = async (request, setErr, setLoading, navigate, dispatch) => {
  setLoading(true)
  if (request.file && request?.file[0]) {
    let fileUrl = await reqUploadImage(request.file[0], setErr)
    request.image_upload = null
    request.file = null
    request.image = fileUrl
  }

  await axios.put('/game/update', request, {
    headers: {
      'Content-Type': 'application/json',
    }
  })
    .then((response) => {
      setLoading(false)
      dispatch(setToInitial(null))
      navigate(-1)
    })
    .catch((e) => {
      setLoading(false)
      if (e?.response?.data?.message) {
        setErr(e.response.data.message + ' cause '+ e.response.data.errors)
      } else {
        setErr(e.message)
      }
    })
}

const reqUploadImage = (file, setErr) => {
  let formData = new FormData();
  formData.append('file', file);
  return axios.post('/image/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((e) => {
      if (e?.response?.data?.message) {
        setErr(e.response.data.message + ' cause '+ e.response.data.errors);
      } else {
        setErr(e.message);
      }
      throw new e;
    });
};

export default GameDetail