import { configureStore } from '@reduxjs/toolkit'
import navigationReducer from './features/navigation/navigation'
import { apiSlice } from './features/apiSlice/apiSlice'
import gameSlice from './hooks/game/gameSlice'
import authSlice from './hooks/auth/authSlice'

export const store = configureStore({
  reducer: {
    navigation: navigationReducer,
    game: gameSlice,
    auth: authSlice,
    [apiSlice.reducerPath]: apiSlice.reducer
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true
})
