import React from 'react'
import { WEB_ICON } from '../../assets/assets'
import { useSelector, useDispatch } from 'react-redux'
import { changeLocation } from '../../features/navigation/navigation'
import { sideLinks } from '../../constants/sideLink'
import RecursiveLink from './RecursiveLink'

const Sidebar = ({navOpen, setNavOpen}) => {
  const dispatch = useDispatch()
  const handleChangeLocation = (location) => {
    dispatch(
      changeLocation(location)
    )
  }

  const nav = useSelector(state => state.navigation)
  const linkElement = RecursiveLink(sideLinks, nav.location, handleChangeLocation)

  return (
    <>
      <div className={`bg-black/50 w-full h-full fixed top-0 right-0 z-40 ${navOpen ? 'block md:hidden' : 'hidden md:hidden'}`} onClick={() => setNavOpen(!navOpen)}></div>
      <nav className={`fixed w-[250px] md:w-[350px] in-bg-default h-screen px-10 py-6 z-50 ${navOpen ? 'block md:block' : 'hidden md:block'}`}>
        <div className='px-4'>
          <img src={WEB_ICON} alt="icon" className='w-full' />
        </div>
        <div>
          <ul className='list-none text-white'>
            {linkElement}
          </ul>
        </div>
      </nav>
    </>
  )
}

export default Sidebar