import { createAsyncThunk } from "@reduxjs/toolkit";
import { loginFailure, loginStart, loginSuccess } from "../authSlice";
import { saveUserToLocalStorage } from "../util/authUtil";
import axios from '../../../api/axios'

export const refreshTokenThunk = createAsyncThunk(
  'auth/refreshToken',
async (userData, thunkApi) => {
  try {
    thunkApi.dispatch(loginStart())
    const response = await axios.post('/admin/refresh', {token: userData?.auth?.refresh}, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    saveUserToLocalStorage(response.data.data)
    thunkApi.dispatch(loginSuccess(response.data.data))
  } catch (error) {
    thunkApi.dispatch(loginFailure(error.message))
  }
})