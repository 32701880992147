import React from 'react'

const PaymentCard = ({name, image, onClick, active}) => {
  return (
    <>
      <div onClick={onClick} className='bg-yellow-300 relative rounded-lg cursor-pointer shadow-transparent shadow-lg hover:shadow-slate-200 transition-all duration-150'>
        <div className={`absolute w-5 h-5 rounded-full right-0 -mr-1 -mt-1 ${active ? 'bg-blue-800' : 'bg-red-800'}`}/>
        <div className='w-50 h-50 bg-white/70'>
          <img src={image} alt="dana-payment" className='w-full' />
        </div>
        <span className='font-bold py-2 px-5 block text-[#171717]'>{name}</span>
      </div>
    </>
  )
}

export default PaymentCard