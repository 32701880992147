import { faBoxesStacked, faCartArrowDown, faGamepad, faHome, faWallet } from "@fortawesome/free-solid-svg-icons";


export const sideLinks = [
  {
    parent: true,
    text: "Dashboard",
    childs: [
      {
        parent: false,
        icon: faHome,
        text: "Home",
        href: "/",
      },
      {
        parent: false,
        icon: faGamepad,
        text: "Games",
        href: "/game",
      },
      {
        parent: false,
        icon: faBoxesStacked,
        text: "Items",
        href: "/item",
      },
      {
        parent: false,
        icon: faWallet,
        text: "Payments",
        href: "/payment",
      },
    ]
  },
  {
    parent: true,
    text: "Transactions",
    childs: [
      {
        parent: false,
        icon: faCartArrowDown,
        text: "Transactions",
        href: "/transaction",
      },
    ]
  }
]