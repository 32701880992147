import { faAnglesLeft, faAnglesRight, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export default function ClearPagination({ data, page, setPage }) {
  const handleChangePage = (page) => {
    setPage(page)
  }
  return (
    <div className='flex py-5'>
      <div className='ml-auto text-gray-500'>
        {page > 3 &&
          <span className='border-t-2 border-transparent px-5 py-2 hover:cursor-pointer hover:bg-gray-50 rounded-md' onClick={() => handleChangePage(1)}><FontAwesomeIcon icon={faAnglesLeft} /></span>
        }
        {page > 1 &&
          <span className='border-t-2 border-transparent px-5 py-2 hover:cursor-pointer hover:bg-gray-50 rounded-md' onClick={() => handleChangePage(page - 1)}><FontAwesomeIcon icon={faChevronLeft} /></span>
        }
        {page > 3 &&
          <span className='border-t-2 border-transparent px-4 py-2 text tracking-widest'>.....</span>
        }
        {page - 2 > 0 &&
          <span className='border-t-2 border-transparent px-4 py-2 hover:cursor-pointer hover:bg-gray-50 rounded-md' onClick={() => handleChangePage(page - 2)}>{page - 2}</span>
        }
        {page - 1 > 0 &&
          <span className='border-t-2 border-transparent px-4 py-2 hover:cursor-pointer hover:bg-gray-50 rounded-md' onClick={() => handleChangePage(page - 1)}>{page - 1}</span>
        }
        <span className='border-t-2 border-t-blue-500 text-blue-500 px-4 py-2'>{page}</span>
        {page + 1 <= data.total_page &&
          <span className='border-t-2 border-transparent px-4 py-2 hover:cursor-pointer hover:bg-gray-50 rounded-md' onClick={() => handleChangePage(page + 1)}>{page + 1}</span>
        }
        {page + 2 <= data.total_page &&
          <span className='border-t-2 border-transparent px-4 py-2 hover:cursor-pointer hover:bg-gray-50 rounded-md' onClick={() => handleChangePage(page + 2)}>{page + 2}</span>
        }
        {(data?.total_page - page) > 3 &&
          <span className='border-t-2 border-transparent px-4 py-2 text tracking-widest'>.....</span>
        }
        {page < data?.total_page &&
          <span className='border-t-2 border-transparent px-5 pl-10 py-2 hover:cursor-pointer hover:bg-gray-50 rounded-md' onClick={() => handleChangePage(page + 1)}><FontAwesomeIcon icon={faChevronRight} /></span>
        }
        {page < (data?.total_page - 3) &&
          <span className='border-t-2 border-transparent px-5 py-2 hover:cursor-pointer hover:bg-gray-50 rounded-md' onClick={() => handleChangePage(data?.total_page)}><FontAwesomeIcon icon={faAnglesRight} /></span>
        }
      </div>
    </div>
  )
}
