import React from 'react'

export const ButtonBlue = ({text, icon, onClick, className, disabled}) => {
  return (
    <>
      <button type="button" className={` ${!className?.includes('text-') ? 'text-white' : ''} ${!className?.includes('bg-') ? 'bg-blue-700 hover:bg-blue-800' : ''}  font-medium rounded-lg text-xs md:text-sm px-5 py-2.5 ${className}`} onClick={onClick} disabled={disabled}>
        {text ?? ''} 
        {icon ? <span className={text && 'pl-3'}>{icon}</span> : ""}
      </button>
    </>
  )
}

export const ButtonTransaparent = ({text, icon, onClick, color, disabled}) => {
  return (
    <>
      <button type="button" className="font-medium rounded-lg text-sm px-5 py-2.5" onClick={onClick} disabled={disabled}>
        {text ?? ''} 
        {icon ? <span className={text && 'pl-3'}>{icon}</span> : ""}
      </button>
    </>
  )
}