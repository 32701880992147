import { Link } from "react-router-dom";

export default function GameItem({ name, image, to, status }) {
  return (
    <>
      <Link to={to}>
        <div className="w-full from-slate-50 to-slate-200 hover:from-slate-100 hover:to-slate-200 bg-gradient-to-b p-2 rounded-xl relative hover:shadow-lg hover:cursor-pointer transition-all duration-300">
          <div className={`w-5 h-5 ${status === 'active' ? 'bg-blue-500' : 'bg-gray-500'} rounded-3xl absolute right-0 -mr-1 -mt-2`} />
          <div className="overflow-hidden rounded-3xl">
            <img src={image} alt={name} className="w-full h-auto" />
          </div>
          <span className="font-semibold text-[18px] text-center block py-3">{name}</span>
        </div>
      </Link >
    </>
  )
}
