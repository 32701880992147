import React from 'react'
import { ButtonTransaparent } from '../buttons/Buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'

const Transaction = ({ data, no, view }) => {
  return (
    <>
      <tr className="">
        <td className="px-2 pl-10 py-1">
          {no}
        </td>
        <td className="px-6 py-1 capitalize">
          {data?.transaction_code}
        </td>
        <td className="px-6 py-1 capitalize">
          {data?.transaction_admin ? 'Admin' : 'User'}
        </td>
        <td className="px-6 py-1 capitalize">
          {formatDate(data?.created_at)}
        </td>
        <td className="px-6 py-1 capitalize">
          {data?.item?.code}
        </td>
        <td className="px-6 py-1 uppercase">
          {data?.status}
        </td>
        <td className="px-6 py-1 normal-case">
          {data?.item?.name}
        </td>
        <td className="px-6 py-1 normal-case">
          {new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(data?.sell_price)}
        </td>
        <td className="px-6 py-1 capitalize">
          <ButtonTransaparent icon={<FontAwesomeIcon icon={faEye} onClick={view} />} />
        </td>
      </tr>
    </>
  )
}

const formatDate = (timestampString) => {
  const date = new Date(timestampString);

  const day = ('0' + date.getDate()).slice(-2); 
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();
  const hours = ('0' + date.getHours()).slice(-2);
  const minutes = ('0' + date.getMinutes()).slice(-2);
  const seconds = ('0' + date.getSeconds()).slice(-2);

  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}

export default Transaction