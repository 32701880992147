import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import Layout from './layouts/Layout';
import Home from './page/Home';
import Game from './page/Game';
import GameDetail from './page/GameDetail';
import GameCreate from './page/GameCreate';
import Item from './page/Item';
import Transaction from './page/Transaction';
import Login from './page/Login';
import Payment from './page/Payment';
import PaymentDetail from './page/PaymentDetail';

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/' element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='/game' element={<Game />} />
          <Route path='/game/:slug' element={<GameDetail />} />
          <Route path='/game/create' element={<GameCreate />} />
          <Route path='/item' element={<Item />} />
          <Route path='/transaction' element={<Transaction />} />
          <Route path='/payment' element={<Payment />} />
          <Route path='/payment/:paymentID' element={<PaymentDetail />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App