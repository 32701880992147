import React, { useEffect, useState } from 'react'
import HeaderSection from '../headers/HeaderSection'
import { ButtonBlue } from '../buttons/Buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { InputWithLabel } from '../forms/FormInputs'
import LayoutLoad from '../../layouts/LayoutLoad'
import axios from '../../api/axios'
import Transaction from './Transaction'
import ClearPagination from '../paginations/ClearPagination'
import CreateTransaction from './CreateTransaction'
import TransactionModal from './TransactionModal'

const TransactionList = () => {
  const [transactions, setTransactions] = useState({})
  const [err, setErr] = useState('')
  const [loading, setLoading] = useState(false)

  const [keyword, setKeyword] = useState('')
  const [page, setPage] = useState(1)

  const [createMode, setCreateMode] = useState(false)
  const [openDetail, setOpenDetail] = useState(false)
  const [transactionDetail, setTransactionDetail] = useState(false)

  useEffect(() => {
    if (keyword) { setPage(1) }
    execGetTransactions(setTransactions, setLoading, setErr, keyword, page)
  }, [keyword, page])
  return (
    <>
      {openDetail &&
        <TransactionModal close={() => setOpenDetail(!openDetail)} data={transactionDetail} />
      }
      {createMode && <CreateTransaction cancel={() => setCreateMode(!createMode)} />}
      <div className='block md:flex'>
        <HeaderSection section={'Transaction List'} />
        <div className='ml-auto pr-6 flex py-5 md:py-0'>
          <div className='mr-2'>
            <InputWithLabel name={'search'} id={'search'} type={'text'} value={keyword} placeholder={'Search Transaction'} onchange={(key, value) => setKeyword(value)} />
          </div>
          <ButtonBlue text={'Create Transaction'} icon={<FontAwesomeIcon icon={faPlus} />} onClick={() => setCreateMode(!createMode)} />
        </div>
      </div>
      <LayoutLoad loading={loading} err={err} setErr={(e) => setErr(e)}>
        <div className="overflow-x-auto shadow-md sm:rounded-lg my-5 max-w-[350px] md:max-w-full py-5">
          <table className="text-sm text-left w-full">
            <thead className="text-xs uppercase bg-gray-100">
              <tr>
                <th scope="col" className="px-2 py-3" />
                <th scope="col" className="px-6 py-3">
                  Transaction Code
                </th>
                <th scope="col" className="px-6 py-3">
                  Create By
                </th>
                <th scope="col" className="px-6 py-3">
                  Date/Time
                </th>
                <th scope="col" className="px-6 py-3">
                  Item Code
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Description
                </th>
                <th scope="col" className="px-6 py-3">
                  Prices
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {transactions?.transactions?.map((val, i) =>
                <Transaction data={val} no={((page - 1) * 10) + (i + 1)} key={val.transaction_code} view={() => {
                  setOpenDetail(!openDetail)
                  execGetTransaction(setTransactionDetail, setLoading, setErr, val?.id)
                }} />
              )}
            </tbody>
          </table>
        </div>
      </LayoutLoad>
      <ClearPagination data={transactions} page={page} setPage={(to) => setPage(to)} />
    </>
  )
}

const reqGetTransactions = async (keyword, page) => {
  return await axios.get(`/transaction?keyword=${keyword ?? ''}&page=${page ?? ''}`)
    .then((response) => {
      return response.data.data
    })
    .catch((e) => {
      if (e?.response?.data?.message) {
        throw e.response.data.message + ' cause ' + e.response.data.errors
      } else {
        throw e.message;
      }
    });
}

const execGetTransactions = async (setTransactions, setLoading, setErr, keyword, page) => {
  setLoading(true)
  setTransactions([])
  try {
    const response = await reqGetTransactions(keyword, page)
    setTransactions({ ...response })
    setLoading(false)
  } catch (e) {
    setLoading(false)
    setErr(e)
  }
}

const reqGetTransaction = async (id) => {
  return await axios.get(`/transaction/${id}`)
    .then((response) => {
      return response.data.data
    })
    .catch((e) => {
      if (e?.response?.data?.message) {
        throw e.response.data.message + ' cause ' + e.response.data.errors
      } else {
        throw e.message;
      }
    });
}

const execGetTransaction = async (setTransactions, setLoading, setErr, id) => {
  setLoading(true)
  setTransactions({})
  try {
    const response = await reqGetTransaction(id)
    setTransactions({ ...response })
    setLoading(false)
  } catch (e) {
    setLoading(false)
    setErr(e)
  }
}


export default TransactionList