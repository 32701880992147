import React from 'react'
import HeaderPage from '../components/headers/HeaderPage'
import GameList from '../components/games/GameList'

const Game = () => {
  return (
    <>
      <HeaderPage page={'Games'} />
      <div className='py-10'>
        <GameList />
      </div>
    </>
  )
}

export default Game