import React, { useEffect, useState } from 'react'
import { ButtonTransaparent } from '../buttons/Buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import FormCheckbox from '../forms/FormCheckbox'

const ItemInt = ({ data, setData, ondelete }) => {
  const [prices, setPrices] = useState(getPrices(data))
  useEffect(() => {
    setPrices(getPrices(data))
  }, [data])

  const handleChangeData = (key, value) => {
    let newData = { ...data }
    let priceIndex = newData.prices.findIndex(dat => dat.type === key)
    console.log(priceIndex);

    switch (key) {
      case 'name':
        newData.name = value
        break;

      case 'status':
        let val = value ? 'available' : 'unvailable'
        newData.status = val
        break

      default:
        newData.prices[priceIndex].margin = parseFloat(value)
        break;
    }

    setData(newData)
  }

  return (
    <>
      <div className='bg-gray-100 py-5 px-5 rounded-md relative hover:shadow-md transition-all duration-300 ease-in-out border-2 border-gray-200/35'>
        <div className='absolute z-10 right-0 -mt-3'>
          <ButtonTransaparent icon={<FontAwesomeIcon icon={faTimes} onClick={() => ondelete()} />} />
        </div>
        <span className='block text-[24px] font-semibold'>{data?.code}</span>
        <input
          type="text"
          name={'name'}
          id={'name'}
          value={data?.name}
          onChange={(e) => handleChangeData(e.target.name, e.target.value)}
          className='focus:outline-none border-b-2 border-b-black/10 w-full bg-transparent' />

        <hr className='py-2' />
        <div className='grid grid-cols-5 text-[12px]'>
          <div className='col-span-1'>
            <span className='block'>Guest</span>
            <span className='block'>Reseller</span>
            <span className='block'>Special</span>
          </div>
          <div className='col-span-4'>
            <div className='flex'>
              <span className=''>: {new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(prices?.get('guest')?.price)}</span>
              <div className='flex mr-auto'>
                <span className='px-5'>mr</span>
                <input
                  type="number"
                  name={'guest'}
                  id={'guest'}
                  value={prices?.get('guest')?.mr}
                  onChange={(e) => handleChangeData(e.target.name, e.target.value)}
                  className='focus:outline-none border-b-2 border-b-black/10 text-right w-12' />

              </div>
            </div>
            <div className='flex'>
              <span className=''>: {new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(prices?.get('reseller')?.price)}</span>
              <div className='flex mr-auto'>
                <span className='px-5'>mr</span>
                <input
                  type="number"
                  name={'reseller'}
                  id={'reseller'}
                  value={prices?.get('reseller')?.mr}
                  onChange={(e) => handleChangeData(e.target.name, e.target.value)}
                  className='focus:outline-none border-b-2 border-b-black/10 text-right w-12' />

              </div>
            </div>
            <div className='flex'>
              <span className=''>: {new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(prices?.get('special')?.price)}</span>
              <div className='flex mr-auto'>
                <span className='px-5'>mr</span>
                <input
                  type="number"
                  name={'special'}
                  id={'special'}
                  value={prices?.get('special')?.mr}
                  onChange={(e) => handleChangeData(e.target.name, e.target.value)}
                  className='focus:outline-none border-b-2 border-b-black/10 text-right w-12' />

              </div>
            </div>
          </div>
        </div>
        <hr className='py-2' />
        <FormCheckbox
          name={'status'}
          id={'status'}
          disabled={data.available !== 'available'}
          checked={data.status === 'available' ?? handleChangeData('status', false)}
          onchange={(e, val) => handleChangeData(e, val)}
          label={'Available'} />

        <span className={`text-[14px] ${data.available === 'available' ? 'bg-yellow-500' : 'bg-red-500'} px-3 py-1 rounded-md text-white capitalize`}>Broker : {data.available}</span>
      </div>
    </>
  )
}

const getPrices = (data) => {
  let mapPrices = new Map()
  data.prices.map((val, i) => {
    mapPrices.set(val.type, { price: val.price, mr: val.margin })
  })
  return mapPrices
}

export default ItemInt