import { createAsyncThunk } from "@reduxjs/toolkit";
import { loginFailure, loginStart, loginSuccess } from "../authSlice";
import axios from '../../../api/axios'
import { saveUserToLocalStorage } from "../util/authUtil";

export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (userData, thunkApi) => {
    try {
      thunkApi.dispatch(loginStart())
      const response = await axios.post('/admin/login', userData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      saveUserToLocalStorage(response.data.data)
      thunkApi.dispatch(loginSuccess(response.data.data))
    } catch (error) {
      thunkApi.dispatch(loginFailure(error.response.data.errors))
    }
  }
)