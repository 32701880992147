import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from '../../api/axios'

export const getListGame = createAsyncThunk(
  'fetch/get',
  async (url, thunkAPI) => {
    try {
      const response = await axios.get(url)
      return response.data
    } catch (e) {
      return thunkAPI.rejectWithValue({ error: e.message })
    }
  }
)

const initialState = {
  response: null,
  status: 'idle',
  error: null
}

const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setToInitial() {
      return {
        response: null,
        status: 'idle',
        error: null
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getListGame.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(getListGame.fulfilled, (state, action) => {
      state.status = 'successful'
      state.response = action.payload?.data
    })
    builder.addCase(getListGame.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload.error
    })
  }
  // extraReducers: (builder) => ({
  //   [goGet.pending]: (state, action) => {
  //     state.status = 'loading'
  //   },
  //   [goGet.fulfilled]: (state, action) => {
  //     state.status = 'successful'
  //     state.response = state.response.concat(action.payload)
  //   },
  //   [goGet.rejected]: (state, action) => {
  //     state.status = 'failed'
  //     state.error = action.error.message
  //   }, 
  // })
})

export const { setToInitial } = gameSlice.actions
export default gameSlice.reducer