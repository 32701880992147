import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BG_IMAGE } from "../assets/assets";
import { ButtonTransaparent } from "../components/buttons/Buttons";
import { InputWithLabel } from "../components/forms/FormInputs";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { clearUserFromLocalStorage, getUserFromLocalStorage } from "../hooks/auth/util/authUtil";
import LayoutLoad from "../layouts/LayoutLoad";
import axios from '../api/axios'
import { loginUser } from "../hooks/auth/thunk/loginThunk";
import { logout } from "../hooks/auth/authSlice";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  const navigate = useNavigate()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (getUserFromLocalStorage() && !auth?.loading) {
      navigate('/')
    }

    setLoading(auth?.loading)
    setError(auth.error)
  }, [auth])

  const login = () => {
    clearUserFromLocalStorage()
    dispatch(loginUser({ username, password }))
  }

  return (
    <>
      {/* <div style={{ backgroundImage: `url(${BG_IMAGE})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }} className="w-full h-screen flex blur-lg absolute -z-10"> */}
      <LayoutLoad err={error} setErr={(data) => dispatch(logout())} loading={loading}>
        <div className="w-full h-screen flex blur-lg absolute -z-10">
        </div>
        <div className="w-full flex mb-auto mt-[200px] absolute z-30">
          <div className="h-fit">
            <div className="pb-2 pt-5 px-14 text-white text-lg in-bg-default rounded-tr-2xl">Login</div>
            <div className='py-5 pl-16 pr-5 bg bg-white/60 shadow-md'>
              <InputWithLabel
                type={'text'}
                name={'username'}
                id={'username'}
                label={"Username"}
                value={username}
                readonly={false}
                onchange={(key, value) => setUsername(value)}
                onkeydown={() => ''} />
              <InputWithLabel
                type={'password'}
                name={'password'}
                id={'password'}
                label={"Password"}
                value={password}
                readonly={false}
                onchange={(key, value) => setPassword(value)}
                onkeydown={() => ''} />
              <div className="mt-5">
                <ButtonTransaparent text={'Login'} onClick={() => login()} />
              </div>
            </div>
          </div>
        </div>
      </LayoutLoad>
    </>
  )
}
