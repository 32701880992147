import React, { useEffect, useState } from 'react'
import ErrorPage from '../../page/ErrorPage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import axios from '../../api/axios'

const WigetSaldoInt = ({ className }) => {
  const [data, setData] = useState({})
  const [err, setErr] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    exec(setData, setErr, setLoading)
  }, [])

  return (
    <>
      {err &&
        <ErrorPage err={err} setErr={setErr} />}
      <div className={`border-l-2 border-l-gray-300 py-5 px-5 ${className}`}>
        <span className='block font-semibold text-[24px]'>Total Transaction Acumulation</span>
        <hr />
        {loading ?
          <FontAwesomeIcon icon={faSpinner} spin /> :
          <div className='grid grid-cols-4 text-[14px] py-2'>
            <div className='col-span-1'>
              <span className='block pt-1'>Total Income</span>
              <span className='block pt-1'>Total Spending</span>
              <span className='block pt-1'>Total Margin</span>
              <span className='block pt-1'>Total Transactions</span>
            </div>
            <div className='col-span-3'>
              <span className='block pt-1'>: {new Intl.NumberFormat('id-ID', {style: 'currency', currency: 'IDR'}).format(data?.total_income)}</span>
              <span className='block pt-1'>: {new Intl.NumberFormat('id-ID', {style: 'currency', currency: 'IDR'}).format(data?.total_spending)}</span>
              <span className='block pt-1'>: {new Intl.NumberFormat('id-ID', {style: 'currency', currency: 'IDR'}).format(data?.total_margin)}</span>
              <span className='block pt-1'>: {data?.total_transaction} Success</span>
            </div>
          </div>
        }
      </div>
    </>
  )
}

const req = async () => {
  return await axios.get(`/transaction/count`)
    .then((response) => {
      return response.data.data
    })
    .catch((e) => {
      if (e?.response?.data?.message) {
        throw e.response.data.message + ' cause ' + e.response.data.errors
      } else {
        throw e.message;
      }
    });
}

const exec = async (setData, setLoading, setErr) => {
  setData({})
  setLoading(true)
  try {
    const response = await req()
    setData({ ...response })
    setLoading(false)
  } catch (e) {
    setLoading(false)
    setErr(e)
  }
}

export default WigetSaldoInt