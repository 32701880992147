import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import HeaderSection from '../headers/HeaderSection'
import GameItem from './GameItem'
import { getListGame, setToInitial } from '../../hooks/game/gameSlice'
import LayoutLoad from '../../layouts/LayoutLoad'
import { ButtonBlue } from '../buttons/Buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

export default function GameList() {
  const dispatch = useDispatch()
  const posts = useSelector((state) => state.game)
  const { response, status, error } = posts
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (status === 'idle') {
      dispatch(getListGame("/game"))
    }

    if (status === 'loading') {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [status, dispatch, error])

  return (
    <>
      <LayoutLoad loading={loading} err={error} setErr={(data) => dispatch(setToInitial(data))} >
        <div className='flex'>
          <HeaderSection section={'Game List'} />
          <div className='ml-auto pr-6'>
            <Link to={'create'}>
              <ButtonBlue text={'Add Field'} icon={<FontAwesomeIcon icon={faPlus} />} onClick={() => ''} /></Link>
          </div>
        </div>
        <div className='grid grid-cols-8 gap-5 py-8'>
          {(response) && response.map((val, i) =>
            <div className='col-span-1' key={`game-item-${i}`}>
              <GameItem name={val.name} image={val.image} to={val.slug} status={val.status} />
            </div>
          )}
        </div>
      </LayoutLoad>
    </>
  )
}
