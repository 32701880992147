import React, { useEffect, useState } from 'react'
import HeaderSection from '../headers/HeaderSection'
import { ButtonTransaparent } from '../buttons/Buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const TransactionModal = ({ data, close }) => {
  const [destination, setDestination] = useState('')
  useEffect(() => {
    let destina = ''
    data?.options?.map((val, i) => {
      if (i === 0) {
        destina = val.value
      } else {
        destina = destina + ' | ' + val.value
      }
    })
    setDestination(destina)
  }, [data])

  return (
    <>
      <div className=' fixed top-0 right-0 left-0 z-[100] w-full h-full bg-black/40'>
        <div className='flex justify-center items-center w-full h-full'>
          <div className='relative p-4 w-full max-w-2xl max-h-full'>
            <div className='relative bg-white rounded-lg shadow px-7 py-5'>
              <div className='absolute right-0 -mt-3'>
                <ButtonTransaparent icon={<FontAwesomeIcon icon={faTimes} onClick={close} />} />
              </div>
              <HeaderSection section={'Transaction Detail'} />
              <div className=''>
                <div className='py-3'>
                  <span className='block text-[15px] font-semibold'>Transaction Code</span>
                  <span className='block text-[25px] font-semibold'>{data?.transaction_code}</span>
                  <span className='block text-[15px] font-semibold'>{data?.description}</span>
                  <div className='flex py-1 pt-3 gap-1 text-[12px]'>
                    <span className={`px-4 py-1 bg-yellow-500 text-white ${!data?.histories && 'rounded-r-full'}`}>Progress</span>
                    {data?.histories?.map((val, i) =>
                      <span key={val.id} className={`px-4 py-1 bg-yellow-500 text-white`}>{val.progress}</span>)}
                    {/* {data?.status === 'failed' ?
                      <span className={`px-4 py-1 bg-red-500 text-white rounded-r-full`}>Failed</span> :
                      data?.histories?.map((val, i) =>
                        <span className={`px-4 py-1 ${val?.progress === 'waiting' && 'bg-blue-500'} ${val?.progress === 'success' && 'bg-green-500'} ${data?.histories?.length - 1 === i && 'rounded-r-full'} text-white capitalize`} >{val?.progress}</span>
                      )
                    } */}
                  </div>
                </div>
                <hr />
                <div className='grid grid-cols-4 py-3 text-[14px]'>
                  <div className=''>
                    <span className='block py-1'>Transaction by</span>
                    <span className='block py-1'>Info</span>
                    <span className='block py-1'>Item</span>
                    <span className='block py-1'>Price</span>
                    <span className='block py-1'>Desctination</span>
                  </div>
                  <div className='col-span-3'>
                    <span className='block py-1'>: {data?.transaction_admin ? 'Admin' : 'User'}</span>
                    <span className='block py-1'>: {data?.info}</span>
                    <span className='block py-1'>: {data?.item?.code}</span>
                    <span className='block py-1'>: {data?.sell_price}</span>
                    <span className='block py-1'>: {destination}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TransactionModal