import React from 'react'
import HeaderPage from '../components/headers/HeaderPage'
import WigetSaldoInt from '../components/dashboards/WigetSaldoInt'
import WigetSaldoEKs from '../components/dashboards/WigetSaldoEks'
import WigetTotalMonthlyTransactions from '../components/dashboards/WigetTotalTransaction'
import WigetTotalWeeklyTransactions from '../components/dashboards/WigetTotalTransactionWeekly'
import WigetTotaldTodayTransactions from '../components/dashboards/WigetTotalTransactionToday'

const Home = () => {
  return (
    <>
      <HeaderPage page={'Home'} />
      <div className='grid grid-cols-5 gap-6 py-3'>
        <WigetSaldoEKs className={'col-span-2'} />
      </div>
      <div className='grid grid-cols-5 gap-6 py-3'>
        <WigetSaldoInt className={'col-span-2'}/>
      </div>
      <div className='grid grid-cols-5 gap-6 py-3'>
        <WigetTotalMonthlyTransactions />
        <WigetTotalWeeklyTransactions />
        <WigetTotaldTodayTransactions />
      </div>
    </>
  )
}

export default Home