import { createSlice } from '@reduxjs/toolkit'

const currentPath = window.location.pathname.trim(); // Membersihkan spasi putih dari awal dan akhir path
const desiredPath = currentPath.startsWith('/') ? currentPath : `/${currentPath}`; // Memastikan path dimulai dengan '/'

const initialState = {
  location: desiredPath
}
const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    changeLocation(state, action) {
      return {
        ...state,
        location: action.payload
      }
    }
  }
})

export const { changeLocation } = navigationSlice.actions
export default navigationSlice.reducer