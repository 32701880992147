import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL+"game",
  credentials: 'include'
})

export const apiSlice = createApi({
  baseQuery: baseQuery,
  endpoints: builder => ({})
})