import React, { useEffect, useState } from 'react'
import HeaderPage from '../components/headers/HeaderPage'
import HeaderSection from '../components/headers/HeaderSection'
import FormSelect from '../components/forms/FormSelect'
import axios from '../api/axios'
import LayoutLoad from '../layouts/LayoutLoad'
import { InputWithLabel } from '../components/forms/FormInputs'
import FormCheckbox from '../components/forms/FormCheckbox'
import { ButtonBlue } from '../components/buttons/Buttons'
import { faBoxesPacking, faBoxesStacked, faSave, faSearch, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ItemEks from '../components/items/ItemEks'
import ItemInt from '../components/items/ItemInt'

export default function Item() {
  const [err, setErr] = useState('')
  const [loading, setLoading] = useState(false)
  const [game, setGame] = useState([])
  const [eksItems, setEksItems] = useState([])
  const [intItems, setIntItems] = useState([])

  // Eksternal 
  const [isAvailable, setIsAvailable] = useState(true)
  const [keyword, setKeyword] = useState('')
  const [loadingItem, setLoadingItem] = useState(false)

  const handleSubmitSearch = () => {
    execGetEksItems(setEksItems, setLoadingItem, setErr, keyword, isAvailable)
  }

  const handleAddItemFromItemEks = (i) => {
    let eksData = eksItems[i]
    let intDatas = [...intItems]

    let index = intDatas.findIndex(data => data?.eks_code === eksData.code)
    if (index > -1) {
      intDatas[index].status = eksData?.status
      intDatas[index].available = eksData?.status

      let indexGuest = intDatas[index].prices.findIndex(data => data.type === 'guest')
      intDatas[index].prices[indexGuest].price = eksData?.price?.special
      let indexReseller = intDatas[index].prices.findIndex(data => data.type === 'reseller')
      intDatas[index].prices[indexReseller].price = eksData?.price?.special
      let indexSpecial = intDatas[index].prices.findIndex(data => data.type === 'special')
      intDatas[index].prices[indexSpecial].price = eksData?.price?.special
    } else {
      intDatas.push({
        name: eksData?.name,
        code: eksData?.code,
        eks_code: eksData?.code,
        status: eksData?.status,
        available: eksData?.status,
        prices: [
          {
            type: 'guest',
            margin: 0,
            price: eksData?.price?.special,
          },
          {
            type: 'reseller',
            margin: 0,
            price: eksData?.price?.special,
          },
          {
            type: 'special',
            margin: 0,
            price: eksData?.price?.special,
          }
        ],
        game: {
          name: eksData?.game
        }
      })
    }


    setIntItems(intDatas)
  }

  // Internal 
  const [id, setId] = useState('')
  const [loadingItemInt, setLoadingItemInt] = useState(false)

  const handleOnChange = (key, value) => {
    setId(value)
    execGetIntItems(setIntItems, setLoadingItemInt, setErr, value)
  }

  const handleChangeDataInt = (data, index) => {
    console.log('setdata');
    let newData = [...intItems]
    console.log(data);
    newData[index] = data
    setIntItems(newData)
  }

  const handleSaveItems = () => {
    let tempPayload = []
    intItems.map((val) => {
      delete val.code
      delete val.game
      delete val.id

      let tempPrices = []
      val.prices.map((price) => {
        delete price.id
        delete price.item_id
        tempPrices.push(price)
      })

      val.prices = tempPrices
      tempPayload.push(val)
    })
    exeSaveIntItems(setIntItems, setLoadingItemInt, setErr, id, tempPayload)
  }

  const handleDeleteItemFromItemInt = (i) => {
    let tempItems = [...intItems]
    tempItems.splice(i, 1)
    setIntItems(tempItems)
  }

  // Game List 
  useEffect(() => {
    setLoading(true)
    const execGetGame = async () => {
      try {
        const response = await reqGameList()
        setGame([
          ...response.map((val) => ({ value: val.id, text: val.name }))
        ])
      } catch (e) {
        setLoading(false)
        setErr(e)
      }
    }

    execGetGame()
    setLoading(false)
  }, [])

  return (
    <>
      <LayoutLoad err={err} loading={loading} setErr={(data) => setErr(data)}>
        <HeaderPage page={'Items'} />
        <div className='py-10'>
          <div className='flex'>
            <HeaderSection section={'Item List'} />
            <div className='ml-auto'>
              <FormSelect
                id={'game_select'}
                name={'game_select'}
                defaultData={'Choose Game'}
                options={game}
                disabled={false}
                selected={id}
                onchange={handleOnChange} />
            </div>
          </div>
        </div>
        <div className='grid grid-cols-2 gap-10'>
          <div className='border-stone-700 border-l-2 items-end'>
            <div className='flex w-full items-center px-7 pr-4 bg-gray-100'>
              <span><FontAwesomeIcon className='pr-3' icon={faBoxesPacking} /> External Items {loadingItem && <FontAwesomeIcon icon={faSpinner} spin />}</span>
              <div className='ml-auto flex items-center gap-x-5'>
                <div className='mt-4'>
                  <FormCheckbox
                    id={'isAvailable'}
                    name={'isAvailable'}
                    label={'is available'}
                    checked={isAvailable}
                    onchange={(key, val) => setIsAvailable(val)} />
                </div>
                <InputWithLabel
                  id={'keyword'}
                  name={'keyword'}
                  type={'text'}
                  placeholder={'Game name'}
                  onchange={(key, val) => setKeyword(val)} />
                <ButtonBlue icon={<FontAwesomeIcon icon={faSearch} />} onClick={handleSubmitSearch} />
              </div>
            </div>
            <div className='pl-5 py-5 grid grid-cols-2 gap-3'>
              {eksItems.map((val, i) =>
                <div className='col-span-1' key={val.code + i}>
                  <ItemEks data={val} onadd={() => handleAddItemFromItemEks(i)} />
                </div>
              )}

            </div>
          </div>
          <div className='border-stone-700 border-l-2 items-end'>
            <div className='flex w-full items-center px-7 pr-4 bg-gray-100'>
              <span className='py-[14px]'><FontAwesomeIcon className='pr-3' icon={faBoxesStacked} />Internal Items {loadingItemInt && <FontAwesomeIcon icon={faSpinner} spin />}</span>
              <div className='ml-auto'>
                <ButtonBlue text={'Save Changes'} icon={<FontAwesomeIcon icon={faSave} />} onClick={handleSaveItems} />
              </div>
            </div>
            <div className='pl-5 py-5 grid grid-cols-2 gap-3'>
              {intItems.map((val, i) =>
                <div className='col-span-1' key={val + i}>
                  <ItemInt data={val} setData={(data) => handleChangeDataInt(data, i)} ondelete={() => handleDeleteItemFromItemInt(i)} />
                </div>
              )}
            </div>
          </div>
        </div>
      </LayoutLoad>
    </>
  )
}


const reqGameList = async () => {
  return await axios.get('/game')
    .then((response) => {
      return response.data.data
    })
    .catch((e) => {
      if (e?.response?.data?.message) {
        throw e.response.data.message + ' cause ' + e.response.data.errors
      } else {
        throw e.message;
      }
    });
}

const reqEksItems = async (keyword, isAvailable) => {
  return await axios.get(`/item/sync?${isAvailable ? 'isAvailable=true' : ''}${isAvailable ? '&' : ''}${keyword ? 'keyword=' + keyword : ''}`)
    .then((response) => {
      return response.data.data
    })
    .catch((e) => {
      if (e?.response?.data?.message) {
        throw e.response.data.message + ' cause ' + e.response.data.errors
      } else {
        throw e.message;
      }
    });
}

const execGetEksItems = async (setEksItems, setLoadingItem, setErr, keyword, isAvailable) => {
  setLoadingItem(true)
  setEksItems([])
  try {
    const response = await reqEksItems(keyword, isAvailable)
    setEksItems([
      ...response
    ])
    setLoadingItem(false)
  } catch (e) {
    setLoadingItem(false)
    setErr(e)
  }
}

const reqIntItems = async (id) => {
  return await axios.get(`/item/${id}`)
    .then((response) => {
      return response.data.data
    })
    .catch((e) => {
      if (e?.response?.data?.message) {
        throw e.response.data.message + ' cause ' + e.response.data.errors
      } else {
        throw e.message;
      }
    });
}

const execGetIntItems = async (setIntItems, setLoadingItemInt, setErr, id) => {
  if (id !== 'Choose Game') {
    setIntItems([])
    setLoadingItemInt(true)
    try {
      const response = await reqIntItems(id)
      console.log(response);
      setIntItems([
        ...response
      ])
      setLoadingItemInt(false)
    } catch (e) {
      setLoadingItemInt(false)
      setErr(e)
    }
  }
}

const reqSaveIntItems = async (id, payload) => {
  return await axios.post(`/item/assign/${id}`, payload, {
    headers: {
      'Content-Type': 'application/json',
    }
  })
    .then((response) => {
      return response.data.data
    })
    .catch((e) => {
      if (e?.response?.data?.message) {
        throw e.response.data.message + ' cause ' + e.response.data.errors
      } else {
        throw e.message;
      }
    })
}

const exeSaveIntItems = async (setIntItems, setLoadingItemInt, setErr, id, payload) => {
  setLoadingItemInt(true)
  setIntItems([])
  try {
    const response = await reqSaveIntItems(id, payload)
    console.log(response);
    setIntItems([
      ...response
    ])
    setLoadingItemInt(false)
  } catch (e) {
    setLoadingItemInt(false)
    setErr(e)
  }
}