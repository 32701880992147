import React from 'react'

export const InputWithLabel = ({ type, name, id, label, readonly, value, onchange, placeholder, onkeydown }) => {
  return (
    <>
      <div>
        {label &&
          <label className='px-2 text-[14px]' htmlFor={id}>{label}</label>
        }
        <input className='w-full focus:outline-none border-2 border-[#d5dbe4] focus:border-[#9CA3AF] rounded-md px-5 py-2' type={type} name={name} id={id} readOnly={readonly} value={value} onChange={(e) => onchange(e.target.name, e.target.value)} placeholder={placeholder} onKeyDown={(e) => {
          e.key === 'Enter' && onkeydown(e.target.name, e.target.value)
        }} />
      </div>
    </>
  )
}

export const InputTextArea = ({ name, id, label, readonly, value, onchange }) => {
  return (
    <>
      <div>
        <label className='px-2 text-[14px]' htmlFor={id}>{label}</label>
        <textarea name={name} id={id} cols="30" rows="3" readOnly={readonly} className='w-full focus:outline-none border-2 border-[#d5dbe4] focus:border-[#9CA3AF] rounded-md px-5 py-2' value={value} onChange={(e) => onchange(e.target.name, e.target.value)}></textarea>
      </div>
    </>
  )
}
