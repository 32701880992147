import React from 'react'

const FormSelect = ({ id, name, options, selected, label, disabled, onchange, defaultData }) => {
  return (
    <>
      <div>
        {label &&
          <label htmlFor={id} className="px-2 text-[14px]">{label}</label>
        }
        <select value={selected} id={id} name={name} disabled={disabled} onChange={(e) => onchange(e.target.name, e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none" >
          {
            !defaultData ?
              <option>Chose Type</option> : <option>{defaultData}</option>
          }
          {
            options?.map((val, i) =>
              <option key={val + i} value={val.value}>{val.text}</option>
            )
          }
        </select>
      </div>
    </>
  )
}

export default FormSelect