import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './index.css';
import {store} from './store';
import Layout from './layouts/Layout';
import Home from './page/Home';
import Game from './page/Game';
import GameDetail from './page/GameDetail';
import GameCreate from './page/GameCreate';
import Item from './page/Item';
import Transaction from './page/Transaction';
import Login from './page/Login';
import Payment from './page/Payment';
import PaymentDetail from './page/PaymentDetail';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode >
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);