import React from 'react'

const FormCheckbox = ({ name, id, label, disabled, checked, onchange }) => {
  return (
    <>
      <div className="flex items-center mb-4">
        <input checked={checked} id={id} name={name} disabled={disabled} type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded " onChange={(e) => onchange(e.target.name, !checked)}/>
        <label htmlFor={id} className="ms-2 text-[14px]">{label}</label>
      </div>
    </>
  )
}

export default FormCheckbox