import React from 'react'
import ErrorPage from '../page/ErrorPage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const LayoutLoad = ({ children, err, loading, setErr }) => {
  return (
    (loading) ? <FontAwesomeIcon icon={faSpinner} spin /> :
      <>
        {err && <ErrorPage err={err} setErr={setErr} />}
        {children}
      </>
  )
}

export default LayoutLoad