import React from 'react'
import { InputTextArea, InputWithLabel } from '../forms/FormInputs'
import HeaderSection from '../headers/HeaderSection'
import FormFileImage from '../forms/FormFile'
import FormCheckbox from '../forms/FormCheckbox'

const GameSectionDetail = ({ payload, handleChangePayload, editMode }) => {
  const handleChangeImage = (img) => {
    handleChangePayload('image_upload', img)
  }

  const handleChangeFile = (file) => {
    handleChangePayload('file', file)
  }

  const handleChangeStatue = (key, value) => {
    if (value) {
      handleChangePayload(key, 'active')
    } else {
      handleChangePayload(key, 'inactive')
    }
  }

  return (
    <>
      <div className='py-10'>
        <HeaderSection section={'Game Detail'} />
        <div className='grid grid-cols-2 gap-x-10 gap-y-5 bg-white px-5 py-8 shadow-xl rounded-xl'>
          <div>
            <div className='pb-2'>
              <InputWithLabel
                type={'text'}
                name={'developer'}
                id={'developer'}
                label={"Developer"}
                value={payload.get('developer') ?? ''}
                readonly={!editMode} 
                onchange={handleChangePayload}/>
            </div>
            <div className='pb-2'>
              <InputWithLabel
                type={'text'}
                name={'name'}
                id={'name'}
                label={"Name"}
                value={payload.get('name') ?? ''}
                readonly={!editMode} 
                onchange={handleChangePayload}/>
            </div>
            <div className='pb-2'>
              <InputTextArea
                name={'description'}
                id={'description'}
                label={"Description"}
                readonly={!editMode}
                value={payload.get('description') ?? ''}
                onchange={handleChangePayload} />
            </div>
            <div className='pb-2'>
              <FormCheckbox name={'status'} id={'status'} disabled={!editMode} label={'Active'} checked={payload.get('status') === 'active'} onchange={handleChangeStatue} />
              <FormCheckbox name={'check_nickname'} id={'check_nickname'} disabled={!editMode} label={'Check nickname'} checked={payload.get('checking_nickname')} onchange={handleChangePayload} />
            </div>
          </div>
          <div>
            <FormFileImage image={payload.get('image_upload')} setImage={(data) => handleChangeImage(data)} setFile={(data) => handleChangeFile(data)} name={'image'} id={'upload-image'} readonly={!editMode} />
          </div>
        </div>
      </div>
    </>
  )
}

export default GameSectionDetail