import React, { useEffect, useState } from 'react'
import ErrorPage from '../../page/ErrorPage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import axios from '../../api/axios'

const WigetSaldoEKs = ({ className }) => {
  const [data, setData] = useState({})
  const [err, setErr] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    exec(setData, setErr, setLoading)
  }, [])

  return (
    <>
      {err &&
        <ErrorPage err={err} setErr={setErr} />}
      <div className={`bg-gray-200/35 py-5 px-5 rounded-md ${className}`}>
        {loading ?
          <FontAwesomeIcon icon={faSpinner} spin /> :
          <span className='block font-semibold text-[24px]'>Total Remaining Balance {new Intl.NumberFormat('id-ID', {style: 'currency', currency: "IDR"}).format(data?.balance)}</span>
        }
      </div>
    </>
  )
}

const req = async () => {
  return await axios.get(`/transaction/balance`)
    .then((response) => {
      return response.data.data
    })
    .catch((e) => {
      if (e?.response?.data?.message) {
        throw e.response.data.message + ' cause ' + e.response.data.errors
      } else {
        throw e.message;
      }
    });
}

const exec = async (setData, setLoading, setErr) => {
  setData({})
  setLoading(true)
  try {
    const response = await req()
    setData({ ...response })
    setLoading(false)
  } catch (e) {
    setLoading(false)
    setErr(e)
  }
}


export default WigetSaldoEKs