import React from 'react'
import { ButtonTransaparent } from '../buttons/Buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const ItemEks = ({ data, onadd }) => {
  return (
    <>
      <div className='bg-gray-100 py-5 px-5 rounded-md relative hover:shadow-md transition-all duration-300 ease-in-out border-2 border-gray-200/35'>
        {data.status === 'available' &&
          <div className='absolute z-10 right-0 -mt-3'>
            <ButtonTransaparent icon={<FontAwesomeIcon icon={faPlus} onClick={() => onadd()} />} />
          </div>
        }
        <span className='block text-[12px]'>{data.game}</span>
        <span className='block text-[24px] font-semibold'>{data.code}</span>
        <span className='block text-[14px]'>{data.name}</span>
        <hr className='py-2' />
        <div className='grid grid-cols-4 text-[12px]'>
          <div className='col-span-1'>
            <span className='block'>Guest</span>
            <span className='block'>Reseller</span>
            <span className='block'>Special</span>
          </div>
          <div className='col-span-3  '>
            <span className='block'>: {new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(data?.price?.basic)}</span>
            <span className='block'>: {new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(data?.price?.premium)}</span>
            <span className='block'>: {new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(data?.price?.special)}</span>
          </div>
        </div>
      </div>
    </>
  )
}

export default ItemEks