import React from 'react'

export default function HeaderPage({page}) {
  return (
    <>
      <div className='w-full pt-10'>
        <span className='font-semibold text-[14px] block -mb-1'>Page Active</span>
        <span className='font-semibold text-[24px]'>Page {page}</span>
      </div>
    </>
  )
}
