import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import { clearUserFromLocalStorage, getUserFromLocalStorage, saveUserToLocalStorage } from "../hooks/auth/util/authUtil"
import { refreshTokenThunk } from "../hooks/auth/thunk/refreshTokenThunk"

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // withCredentials: true,
  headers: {
    Authorization: 'Bearer ' + getUserFromLocalStorage()?.auth?.token
  }
})

instance.interceptors.response.use((response) => {
  return response
},
  async (error) => {
    const originalConfig = error.config
    if (error.response) {
      if (error.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        await axios.post(process.env.REACT_APP_BASE_URL + '/admin/refresh', { token: getUserFromLocalStorage()?.auth?.refresh }, {
          headers: {
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          saveUserToLocalStorage(response.data.data)
        }).catch((error) => {
          clearUserFromLocalStorage()
          window.location.href = '/login'
          return Promise.reject(error);
        })

        originalConfig.headers['Authorization'] = 'Bearer ' + getUserFromLocalStorage()?.auth?.token
        return instance(originalConfig);
      }

      if (error.response.status === 400) {
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
)

// instance.defaults.headers.common['Content-Type'] = 'application/json'
// instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// instance.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET, POST, PUT, DELETE, OPTIONS';
// instance.defaults.headers.common['Access-Control-Allow-Headers'] = 'Origin, Authorization, Content-Type';
// instance.defaults.headers.common['Access-Control-Max-Age'] = '3600';

export default instance
