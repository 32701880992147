import React, { useState } from 'react'
import GameSectionDetail from '../components/games/GameSectionDetail'
import GameSectionFIeld from '../components/games/GameSectionFIeld'
import { ButtonBlue, ButtonTransaparent } from '../components/buttons/Buttons'
import LayoutLoad from '../layouts/LayoutLoad'
import HeaderPage from '../components/headers/HeaderPage'
import axios from '../api/axios'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setToInitial } from '../hooks/game/gameSlice'

export default function GameCreate() {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  
  const editMode = true
  const [err, setErr] = useState('')
  const [loading, setLoading] = useState(false)
  const [payload, setPayload] = useState(new Map().set('fields', []))

  const handleChangePayload = (key, value) => setPayload(prevPayload => new Map(prevPayload).set(key, value));
  const handleCreate = () => {
    const fields = []
    const file = payload.get('file')
    payload.get('fields').forEach((field, i) => {
      let tempField = {}
      let tempOptions = []
      field?.get('options')?.forEach(option => {
        tempOptions.push(JSON.parse(JSON.stringify(Object.fromEntries(option))))
      })

      tempField = JSON.parse(JSON.stringify(Object.fromEntries(field)))
      tempField.options = tempOptions
      fields.push(tempField)
    })
    let request = JSON.parse(JSON.stringify(Object.fromEntries(payload)))
    request.fields = fields
    request.file = file
    reqUpdate(request, (e) => setErr(e), (e) => setLoading(e), navigate, dispatch)
  }

  return (
    <>
      <LayoutLoad err={err} loading={loading} setErr={(data) => setErr(data)}>
        <HeaderPage page={`Create game`} />
        <GameSectionDetail payload={payload} handleChangePayload={(key, value) => handleChangePayload(key, value)} editMode={editMode} />
        <GameSectionFIeld payload={payload} handleChangePayload={(key, value) => handleChangePayload(key, value)} editMode={editMode} />
        <div className='flex float-end'>
          <ButtonTransaparent text={'Cancel'} onClick={() => navigate(-1)} />
          <ButtonBlue text={'Save Game'} onClick={handleCreate} />
        </div>
      </LayoutLoad>
    </>
  )
}

const reqUpdate = async (request, setErr, setLoading, navigate, dispatch) => {
  setLoading(true)
  if (request.file && request?.file[0]) {
    let fileUrl = await reqUploadImage(request.file[0], setErr)
    request.image_upload = null
    request.file = null
    request.image = fileUrl
  }

  await axios.post('/game/create', request, {
    headers: {
      'Content-Type': 'application/json',
    }
  })
    .then((response) => {
      setLoading(false)
      dispatch(setToInitial(null))
      navigate(-1)
    })
    .catch((e) => {
      setLoading(false)
      if (e?.response?.data?.message) {
        setErr(e.response.data.message + ' cause '+ e.response.data.errors)
      } else {
        setErr(e.message)
      }
    })
}

const reqUploadImage = (file, setErr) => {
  let formData = new FormData();
  formData.append('file', file);
  return axios.post('/image/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((e) => {
      if (e?.response?.data?.message) {
        setErr(e.response.data.message + ' cause '+ e.response.data.errors);
      } else {
        setErr(e.message);
      }
      throw e;
    });
};
